import { useNavigate } from "react-router-dom";
import "./Confirmation.css";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";

function Confirmation({ confirmed, email = null }) {
  const [send, setSend] = useState(confirmed); // Initialize with the prop value
  const navigate = useNavigate();

  useEffect(() => {
    setSend(confirmed); // Update state whenever `confirmed` changes
  }, [confirmed]);

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="relative border-2 border-white aspect-3/4 max-w-[300px] w-[80%] sm-shadow rounded-lg bg-white">
      <div className="relative top-[-10%] left-[50%] translate-x-[-50%] h-1/4 translate-y-[50%] xxs-shadow">
        <div className="swal-icon swal-icon--success">
          <span className="swal-icon--success__line swal-icon--success__line--long"></span>
          <span className="swal-icon--success__line swal-icon--success__line--tip"></span>
          <div className="swal-icon--success__ring"></div>
          <div className="swal-icon--success__hide-corners"></div>
        </div>
      </div>
      {!send ? (
        <div className="w-full h-2/3 absolute bg-white bottom-0 text-center pt-8">
          <h1 className="text-4xl excelFont">Merci!</h1>
          <h2 className="">Inscription Terminée</h2>
          <p className="p-4 pb-6">
            Un message de confirmation sera envoyé à votre courriel.{" "}
            <b>{email}</b>
          </p>
          <div className={`bouncy-transition hover:scale-110`}>
            <a
              href={`/Cours`}
              className={`bg-black relative text-white title border-4 border-black select-none cursor-pointer fade-in xs-shadow p-2 rounded-xl text-4xl z-40`}
            >
              Retour au cours
            </a>
          </div>
        </div>
      ) : (
        <div className="w-full h-2/3 absolute bg-white bottom-0 text-center pt-8">
          <h1 className="text-4xl excelFont">Merci!</h1>
          <h2 className="">Inscription Terminée</h2>
          <p className="p-4 pb-6">
            Vous serez contacté pour enregistrer votre profile. <b>{email}</b>
          </p>
          <div className={`bouncy-transition hover:scale-110`}>
            <a
              href={`/Cours`}
              className={`bg-black relative text-white title border-4 border-black select-none cursor-pointer fade-in xs-shadow p-2 rounded-xl text-4xl z-40`}
            >
              Retour au cours
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default Confirmation;
