import React, { useEffect, useState } from "react";
import Home from "./pages/Home";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { makeRequest } from "./makeRequest";
import EmojiLogo from "./components/EmojiLogo";
import LogoAnimation from "./components/LogoAnimation";
import About from "./pages/About";
import Cours from "./pages/Cours";
import FAQ from "./pages/FAQ";
import Forfaits from "./pages/Forfaits";
import Register from "./pages/Register";
import Pricing from "./components/Pricing";
import Login from "./pages/Login";
import Coursmanager from "./admin/Coursmanager";
import Usersmanager from "./admin/Usersmanager";
import CreateClient from "./admin/CreateClient";
import CancelRegistration from "./pages/CancelRegistrations";
import ClientsManager from "./admin/ClientsManager";
import RegistrationsManager from "./admin/RegistrationsManager";
import FAQManager from "./admin/FAQManager";
import TrainersManager from "./admin/TrainersManager";

function App() {
  useEffect(() => {
    setInterval(() => {
      if (localStorage.refresh) {
        makeRequest
          .post("/users/refresh", {
            refreshToken: localStorage.refresh,
          })
          .then((res) => {
            localStorage.setItem("token", res.data.newAccessToken);
            localStorage.setItem("refresh", res.data.refreshToken);
          })
          .catch((err) => {
            if (localStorage.token) {
              localStorage.removeItem("token");
            }
            localStorage.removeItem("refresh");
          });
      }
    }, 600000);
  }, []);

  return (
    <Router basename="/">
      <Nav />
      <LogoAnimation></LogoAnimation>
      <div
        className="w-screen h-screen fixed top-0 left-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/kisss-background.png)`,
        }}
      >
        <img
          className="xs-shadow absolute sm:bottom-12 lg:bottom-0 right-0  sm:w-[250px] lg:w-[500px]"
          src={`${process.env.PUBLIC_URL}/assets/kisss_rope.png`}
        ></img>
        <img
          className="xs-shadow absolute sm:bottom-2 lg:bottom-0 right-2  sm:w-[150px] lg:w-[200px]"
          src={`${process.env.PUBLIC_URL}/assets/kisss_dumbell.png`}
        ></img>

        <img
          className="xs-shadow absolute sm:bottom-8 lg:bottom-0 left-0 -translate-x-4 sm:w-[200px] lg:w-[300px]"
          src={`${process.env.PUBLIC_URL}/assets/kisss_kilobell.png`}
        ></img>
      </div>
      <div className="gradient w-full flex-col min-h-screen relative m-0 flex ">
        <Routes>
          <Route path="/" element={<Navigate to="/Home" />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Cours" element={<Cours />} />
          <Route path="/Cours/:id" element={<Register />} />
          <Route path="/Tarifs" element={<Pricing />} />
          <Route path="/Faq" element={<FAQ />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Coursmanager" element={<Coursmanager />} />
          <Route path="/ClientsManager" element={<ClientsManager />} />
          <Route path="/Usersmanager" element={<Usersmanager />} />
          <Route path="/CreateClient/:id" element={<CreateClient />} />
          <Route
            path="/RegistrationsManager/:id"
            element={<RegistrationsManager />}
          />
          <Route path="/FAQManager" element={<FAQManager />} />
          <Route path="/TrainersManager" element={<TrainersManager />} />
          <Route
            path="/cancelRegistration/:id"
            element={<CancelRegistration />}
          />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
