import { useState } from "react";
import { makeRequest } from "../../makeRequest";
import { Button, TextField } from "@mui/material";

const EditUsernameForm = ({ userId }) => {
  const [newUsername, setNewUsername] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await makeRequest.put(`/users/${userId}/username`, {
        username: newUsername,
      });
      alert(response.data.message);
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        variant="standard"
        label="Username"
        placeholder="New Username"
        value={newUsername}
        onChange={(e) => setNewUsername(e.target.value)}
        required
        sx={{ maxWidth: "300px" }}
      />
      <Button sx={{ maxWidth: "300px" }} variant="contained" type="submit">
        Update Username
      </Button>
    </form>
  );
};

export default EditUsernameForm;
