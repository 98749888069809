import { useState } from "react";
import { makeRequest } from "../../makeRequest";
import { Button, TextField } from "@mui/material";

const CreateUserForm = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await makeRequest.post("/users/register", {
        email,
        username,
        password,
      });
      alert(response.data.message);
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="gap-2 p-8  lg:w-1/2 h-fit flex flex-col items-center bg-white border-[16px] border-black rounded sm:w-full  mx-auto sm-shadow"
    >
      {" "}
      <TextField
        variant="standard"
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        sx={{ maxWidth: "300px" }}
      />
      <TextField
        variant="standard"
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
        sx={{ maxWidth: "300px" }}
      />
      <TextField
        type="password"
        variant="standard"
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        sx={{ maxWidth: "300px" }}
      />
      <Button sx={{ maxWidth: "300px" }} variant="contained" type="submit">
        Create User
      </Button>
    </form>
  );
};

export default CreateUserForm;
