import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import React, { useMemo, useState, useEffect } from "react";
import Title from "../components/Title";
import { makeRequest } from "../makeRequest";
import { CircularProgress } from "@mui/material";

const preprocessEvents = (data) => {
  return data.map((item) => ({
    _id: item._id,
    title: item._id,
    members: item.members,
    maxMembers: item.maxMembers,
    start: new Date(item.start),
    end: new Date(item.end),
    startDate: new Date(item.start).toISOString().split("T")[0],
    endDate: new Date(item.end).toISOString().split("T")[0],
    startTime: new Date(item.start).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
    endTime: new Date(item.end).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
  }));
};

const groupEventsByDate = (data) => {
  const grouped = preprocessEvents(data).reduce((acc, item) => {
    const start = item.startDate; // Format date as yyyy-MM-dd
    if (!acc[start]) {
      acc[start] = { start: start, events: [] };
    }
    acc[start].events.push(item);
    return acc;
  }, {});

  return Object.values(grouped);
};

// Process the data

function Cours() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  // const groupedData = groupEventsByDate(data);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await makeRequest.get("/cours").then((res) => {
          //console.log(groupEventsByDate(res.data));
          setEvents(groupEventsByDate(res.data));
          setLoading(false);
        }); // Adjust the endpoint as needed
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // const groupedData = useMemo(() => , [data]);
  // const events = useMemo(() => groupedData, [data]);

  const fullCalendarProps = useMemo(
    () => ({
      plugins: [dayGridPlugin],
      initialView: "dayGridMonth",
      defaultAllDay: true,
      height: "auto",

      validRange: {
        start: new Date(),
      },
      expandRows: true,
      eventClick: (e) => {
        handleOpen(e.event);
      },
      events: {
        events,
        color: "black",
        borderColor: "white",
        width: "100px",
      },
    }),
    [events]
  );

  const handleOpen = (event) => {
    setSelectedDate(event);
    const element = document.getElementById("cours");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  function renderEventContent(eventInfo) {
    return (
      <>
        <div
          className={`cours ${
            eventInfo.event.start.getTime() === selectedDate?.start?.getTime()
              ? "bg-black"
              : "bg-stone-300"
          } p-2 duration-200 py-8  rounded m-1  text-wrap text-center cursor-pointer border-2 border-black  flex items-center justify-center`}
        >
          <p className="text-3xl w-fit p-4 px-10 text-black sm-shadow border-4 rounded-full border-black bg-white -rotate-12 excelFont">
            {eventInfo.event.extendedProps.events.length > 1
              ? eventInfo.event.extendedProps.events.length + " Cours"
              : eventInfo.event.extendedProps.events.length + " Cours"}
          </p>
        </div>
      </>
    );
  }

  return (
    <div className="min-h-screen  sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center">
      <div className="md:max-w-[80%] sm:max-w-[95%] flex flex-col items-center">
        {" "}
        <div className="my-16">
          <Title word="Nos Cours" />
        </div>
        {loading ? (
          <CircularProgress> </CircularProgress>
        ) : (
          <div className="w-full flex flex-col">
            <div className="p-4 bg-white w-full excelFont text-2xl text-center my-4 xs-shadow">
              Selectionnez une date
            </div>
            <div className="w-full h-fit sm:p-4 lg:p-0 ">
              <FullCalendar
                eventContent={renderEventContent}
                {...fullCalendarProps}
              />
            </div>
            <div className="w-full mt-12">
              {selectedDate && (
                <div className="p-4 fade-up bg-white w-full excelFont text-2xl text-center my-4 xs-shadow ">
                  Selectionnez un cours
                </div>
              )}

              <div id="cours">
                <div className="flex justify-center my-12">
                  {selectedDate?.extendedProps?.events && (
                    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                      {selectedDate?.extendedProps?.events?.map(
                        (item, index) => (
                          <div
                            key={index}
                            className="w-60 h-60 fade-up excelFont text-3xl border-4 sm-shadow bg-white  border-[#111] rounded-full flex flex-col justify-center items-center"
                          >
                            <p className="excelFont">
                              {item.startTime} - {item.endTime}
                            </p>

                            <p className="excelFont text-4xl">
                              [ {item.members.length} / {item.maxMembers}]
                            </p>
                            <div className="w-fit h-fit relative mt-4">
                              <img
                                className="absolute w-14 -left-16 z-10 top-1/2 -translate-y-2/3 sm-shadow"
                                src={`${process.env.PUBLIC_URL}/assets/muscle.png`}
                              ></img>
                              <img
                                className="absolute scale-x-[-1] w-14 -right-16 z-10 top-1/2 -translate-y-2/3 sm-shadow"
                                src={`${process.env.PUBLIC_URL}/assets/muscle.png`}
                              ></img>
                              <div
                                className={`${
                                  item.members.length === item.maxMembers &&
                                  "pointer-events-none "
                                } bouncy-transition hover:scale-110`}
                              >
                                <a
                                  href={`/Cours/${item._id}`}
                                  className={`${
                                    item.members.length === item.maxMembers &&
                                    "pointer-events-none bg-opacity-30"
                                  } bg-black relative text-white title border-4 border-black select-none cursor-pointer fade-in xs-shadow  p-2  rounded-xl text-4xl z-40`}
                                  disabled={
                                    item.members.length === item.maxMembers
                                  }
                                >
                                  {item.members.length === item.maxMembers
                                    ? "Complet"
                                    : "S'inscrire"}
                                </a>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>{" "}
          </div>
        )}
      </div>
    </div>
  );
}

export default Cours;
