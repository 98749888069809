import "./styles.css";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Title from "./Title";

function Pricing() {
  const location = useLocation();
  const prices = [
    {
      icon: "smiling_imp.png",
      price: 0,
      frequency: "Une Année",
      desc: "On vous fera un prix qui vous donnera accès à tous les activités offertes par KISSS FITNESS au cours de l'année!!",
    },
    {
      icon: "heart_eyes.png",
      price: 49.99,
      frequency: "Un Mois",
      desc: "Cet abonnenemnt vous permet de participer à tous les entrainements du mois dont vous êtes abonné(e).",
    },
    {
      icon: "relieved.png",
      price: 9.99,
      frequency: "Une Session",
      desc: "Cet abonnenemnt vous permet uniquement de participer à l'un de nos fameux entraiments.",
    },
  ];

  const [selectedTab, setSelectedTab] = useState(prices[0]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [location]);

  return (
    <div className="min-h-fit fade-up sm:mt-8  md:my-36 fade-up flex flex-col items-center ">
      <div className="my-16">
        <Title word="Nos Tarifs" />
      </div>
      <div className="window border-8 border-black sm:h-[500px] lg:h-[500px] w-[500px] rounded sm-shadow lg:scale-150 lg:mt-36 lg:mb-36 ">
        <nav>
          <ul className="grid sm:grid-cols-2 md:grid-cols-3 w-full">
            {prices.map((item, index) => (
              <li
                key={item.frequency}
                className={item === selectedTab ? "selected w-full" : ""}
                onClick={() => setSelectedTab(prices[index])}
              >
                {" "}
                <p className="whitespace-nowrap excelFont text-3xl">{`${item.frequency}`}</p>
                <img
                  className="w-8 sm-shadow"
                  src={`${process.env.PUBLIC_URL}/assets/${item.icon}`}
                />
                {item.frequency === selectedTab.frequency ? (
                  <motion.div className="underline z-10" layoutId="underline" />
                ) : null}
              </li>
            ))}
          </ul>
        </nav>
        <div className="flex-1 relative w-full h-full ">
          <AnimatePresence mode="wait">
            <motion.div
              className="flex flex-1 relative w-full h-full"
              key={selectedTab ? selectedTab.frequency : "empty"}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
            >
              {selectedTab ? (
                <div className="w-full h-full flex flex-col justify-center p-8">
                  {selectedTab.price === 0 ? (
                    <div className="relative w-full flex flex-col items-center h-1/3">
                      <p className="excelFont sm:text-7xl lg:text-8xl text-center leading-9 xxs-shadow">
                        Venez Nous
                      </p>
                      <p className="excelFont sm:text-7xl lg:text-8xl  text-center leading-9 xxs-shadow">
                        Voir!!
                      </p>
                      <img
                        className="sm:w-12 sm:h-12 lg:w-20 lg:h-20 sm-shadow absolute sm:-bottom-4 lg:-bottom-12 left-8 -rotate-12"
                        src={`${process.env.PUBLIC_URL}/assets/${selectedTab.icon}`}
                      />
                      <img
                        className="sm:w-12 sm:h-12 lg:w-20 lg:h-20 lg-shadow absolute sm:-bottom-4 lg:-bottom-12 right-8 rotate-12"
                        src={`${process.env.PUBLIC_URL}/assets/${selectedTab.icon}`}
                      />
                    </div>
                  ) : (
                    <div className="relative w-full flex flex-col items-center h-1/3">
                      <p className="excelFont text-9xl text-nowrap xxs-shadow">
                        {selectedTab.price} $
                      </p>
                    </div>
                  )}
                  <div className="w-full relative h-2/3 lg:text-sm flex sm:pt-8 lg:pt-20 text-center">
                    <p>{selectedTab.desc}</p>
                  </div>
                  <AnimatePresence mode="wait"></AnimatePresence>
                  {[...Array(9)].map((_, i) => (
                    <motion.div
                      key={selectedTab ? selectedTab.frequency : "empty"}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -1000, opacity: 1 }}
                      transition={{ duration: 1 }}
                      style={{
                        bottom: `${16 - 60 * i}px `,
                        zIndex: `${20 - i} `,
                      }}
                      className={`grid grid-cols-9 absolute -left-8  `}
                    >
                      {[...Array(9)].map((_, index) => {
                        // Determine the z-index based on the position
                        let zIndex;
                        if (index === 4)
                          zIndex = 34; // Middle image on the first plane
                        else if (index === 3 || index === 5)
                          zIndex = 33; // 4th and 6th on the second plane
                        else if (index === 2 || index === 6)
                          zIndex = 32; // 3rd and 7th on the third plane
                        else if (index === 1 || index === 7)
                          zIndex = 31; // 3rd and 7th on the third plane
                        else zIndex = 30; // Other images on the base layer

                        return (
                          <div
                            key={index}
                            className="relative h-24 w-24"
                            style={{ zIndex }} // Apply z-index dynamically
                          >
                            <img
                              className={`w-24 h-24 sm-shadow absolute left -bottom-8`}
                              src={`${process.env.PUBLIC_URL}/assets/${selectedTab.icon}`}
                              alt={`Icon ${index + 1}`}
                            />
                          </div>
                        );
                      })}
                    </motion.div>
                  ))}
                </div>
              ) : (
                "😋"
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
