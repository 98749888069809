function Title({ word }) {
  const title = () => (
    <div className="relative flex justify-center title text-center ">
      <span className="absolute fontRed z-20 font-extrabold sm:text-6xl md:text-7xl lg:text-8xl text-white">
        {word}
      </span>
      <span
        data-content={`${word}`}
        className="customShadow z-10 font-extrabold sm:text-6xl md:text-7xl lg:text-8xl select-none"
      >
        {word}
      </span>
    </div>
  );

  return <div>{title()}</div>;
}

export default Title;
