import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import { makeRequest } from "../makeRequest";
import Title from "../components/Title";
import { useParams } from "react-router-dom";

const RegistrationsManager = () => {
  const { id } = useParams();
  const [registrations, setRegistrations] = useState([]);
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);

  const date = new Date("1997-01-10T05:00:00.000Z");
  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const [user, setUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);

  // Fetch Current User
  const fetchUser = async () => {
    try {
      const response = await makeRequest.get("/users/me");
      setUser(response.data);
      setIsAuthorized(["user", "admin"].includes(response.data.role));
    } catch (error) {
      console.error("Error fetching user:", error.message);
    }
  };
  const fetchRegistrations = async () => {
    try {
      const response = await makeRequest.get("/registrations/coursId/" + id);
      setRegistrations(response.data);
    } catch (error) {
      console.error("Error fetching Registrations:", error.message);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (isAuthorized) fetchRegistrations();
  }, [isAuthorized]);

  // Delete Registration
  const handleDelete = async (id) => {
    try {
      if (
        window.confirm("Are you sure you want to delete this registration?")
      ) {
        try {
          const response = await makeRequest.get(
            `/registrations/delete-registration/${id}`
          );
          alert(response.data.message);
        } catch (error) {
          alert(error.response?.data?.error || "An error occurred");
        }
      }

      fetchRegistrations();
    } catch (error) {
      console.error("Error deleting Registrations:", error.message);
    }
  };

  if (!isAuthorized) {
    return (
      <div className="min-h-screen  fade-up flex flex-col items-center justify-center">
        <Title word={"User Access"}></Title>
        <Title word={"Only"}></Title>
      </div>
    );
  }

  return (
    <Box className="min-h-screen sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center">
      <div className="my-16">
        <Title word="Registrations Manager" />
      </div>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registrations.map((registration, index) => (
              <TableRow key={registration._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{registration.firstname}</TableCell>
                <TableCell>{registration.lastname}</TableCell>
                <TableCell>
                  {new Date(registration.birth).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </TableCell>
                <TableCell>{registration.email}</TableCell>
                <TableCell>{registration.phone}</TableCell>
                <TableCell>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => handleDelete(registration._id)}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RegistrationsManager;
