import React from "react";

function Trainer({ trainer, odd }) {
  return (
    <>
      {" "}
      {odd ? (
        <div className="relative w-full sm:h-fit lg:h-[400px]  sm:flex  flex-col sm:items-center lg:items-start gap-8 justify-center sm-shadow">
          <div className="bg-white w-80 h-[500px] lg:hidden sm:block border-8 left-0 top-1/2 lg:-translate-y-1/2 lg:-translate-x-1/2 border-black rounded-full  lg:absolute">
            <img
              draggable={false}
              src={trainer.image}
              alt="Background"
              className="w-full h-full lg:hidden sm:block object-cover rounded-full"
            />
          </div>
          <div className="flex flex-col items-center bg-white lg:ml-[194px] lg:h-[300px] sm:w-80 lg:w-[600px] relative border-8 border-black rounded">
            <div className="lg:absolute  lg:right-0 lg:w-2/3 sm:w-5/6 min-h-fit h-[270px] p-2">
              <div className="h-2/5">
                <h1 className="excelFont text-white text-center bg-black sm:p-2 rounded-full sm:text-4xl lg:text-5xl leading-6">
                  {trainer.firstname + " " + trainer.lastname}
                </h1>
                <h2 className="excelFont sm:text-center sm:text-3xl lg:text-4xl sm:px-2  lg:px-4">
                  {trainer.occupations.join("-")}
                </h2>
              </div>

              <div className="flex h-2/5  justify-center items-center">
                <p className=" text-center text-lg px-8 text-balance">
                  {trainer.message}
                </p>
              </div>
              <div className="flex  gap-8 w-full h-1/5 items-center justify-center ">
                <a href="#" className="text-black" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 50 50"
                  >
                    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"></path>
                  </svg>
                  <span className="sr-only">Facebook page</span>
                </a>
                <a href="#" className="text-black" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="32"
                    height="32"
                    viewBox="0 0 30 30"
                  >
                    <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
                  </svg>
                  <span className="sr-only ">Instagram page</span>
                </a>
              </div>
            </div>
            <div className="bg-white w-80 h-[500px] sm:hidden lg:block  border-8 left-0 top-1/2 lg:-translate-y-1/2 lg:-translate-x-1/2 border-black rounded-full sm-shadow lg:absolute">
              <img
                draggable={false}
                src={trainer.image}
                alt="Background"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="relative w-full sm:h-fit lg:h-[400px]  sm:flex  flex-col sm:items-center lg:items-end gap-8 justify-center sm-shadow">
          <div className="bg-white w-80 h-[500px] lg:hidden sm:block border-8 left-0 top-1/2 lg:-translate-y-1/2 lg:-translate-x-1/2 border-black rounded-full  lg:absolute">
            <img
              draggable={false}
              src={trainer.image}
              alt="Background"
              className="w-full h-full lg:hidden sm:block object-cover rounded-full"
            />
          </div>
          <div className="flex flex-col items-center bg-white lg:mr-[194px] lg:h-[300px] sm:w-80 lg:w-[600px] relative border-8 border-black rounded">
            <div className="lg:absolute  lg:left-0 lg:w-2/3 sm:w-5/6 min-h-fit h-[270px] p-2">
              <div className="h-2/5">
                <h1 className="excelFont text-white text-center bg-black sm:p-2 rounded-full sm:text-4xl lg:text-5xl leading-6">
                  {trainer.firstname + " " + trainer.lastname}
                </h1>
                <h2 className="excelFont sm:text-center sm:text-3xl lg:text-4xl sm:px-2  lg:px-4">
                  {trainer.occupations.join("-")}
                </h2>
              </div>

              <div className="flex h-2/5 min-h-fit  justify-center items-center">
                <p className=" text-center text-lg px-8 text-balance">
                  {trainer.message}
                </p>
              </div>
              <div className="flex  gap-8 w-full h-1/5 items-center justify-center ">
                <a href="#" className="text-black" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 50 50"
                  >
                    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"></path>
                  </svg>
                  <span className="sr-only">Facebook page</span>
                </a>
                <a href="#" className="text-black" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="32"
                    height="32"
                    viewBox="0 0 30 30"
                  >
                    <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
                  </svg>
                  <span className="sr-only ">Instagram page</span>
                </a>
              </div>
            </div>
            <div className="w-80 bg-white h-[500px] sm:hidden lg:block  border-8 right-0 top-1/2 lg:-translate-y-1/2 lg:translate-x-1/2 border-black rounded-full sm-shadow lg:absolute">
              <img
                draggable={false}
                src={trainer.image}
                alt="Background"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Trainer;
