import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import { makeRequest } from "../makeRequest";
import Title from "../components/Title";

const Coursmanager = () => {
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    start: "",
    end: "",
    maxMembers: "",
  });
  const [filters, setFilters] = useState({
    start: new Date().toISOString().slice(0, 10), // Today's date
    end: new Date().toISOString().slice(0, 10),
  });
  const [user, setUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);

  // Fetch Current User
  const fetchUser = async () => {
    try {
      const response = await makeRequest.get("/users/me");
      setUser(response.data);
      setIsAuthorized(["user", "admin"].includes(response.data.role));
    } catch (error) {
      console.error("Error fetching user:", error.message);
    }
  };

  // Fetch Courses with Filters
  const fetchCourses = async () => {
    try {
      const response = await makeRequest.get("/cours", {
        params: filters,
      });
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error.message);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (isAuthorized) fetchCourses();
  }, [isAuthorized, filters]);

  // Handle Dialog Open/Close
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditing(false);
    setFormData({ start: "", end: "", maxMembers: "" });
  };

  // Handle Input Change
  const handleChange = (e) => {
    if (e.target.name == "start") {
      console.log(e.target.name);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        end: e.target.value,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // Handle Filter Change
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  // Create or Update Course
  const handleSubmit = async () => {
    try {
      const updatedData = {
        ...formData,
        createdBy: user.username,
        updatedBy: user.username,
      };

      if (editing) {
        await makeRequest.put(`/cours/${formData._id}`, updatedData);
      } else {
        await makeRequest.post("/cours", updatedData);
      }

      fetchCourses();
      handleClose();
    } catch (error) {
      console.error("Error saving course:", error.message);
    }
  };

  // Delete Course
  const handleDelete = async (id) => {
    try {
      await makeRequest.delete(`/cours/${id}`);
      fetchCourses();
    } catch (error) {
      console.error("Error deleting course:", error.message);
    }
  };

  // Open Dialog to Edit Course
  const handleEdit = (course) => {
    setEditing(true);
    setFormData(course);
    handleOpen();
  };

  if (!isAuthorized) {
    return (
      <div className="min-h-screen  fade-up flex flex-col items-center justify-center">
        <Title word={"User Access"}></Title>
        <Title word={"Only"}></Title>
      </div>
    );
  }

  return (
    <Box className="min-h-screen sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center">
      <div className="my-16">
        <Title word="Create Cours" />
      </div>
      {/* Filters */}
      <Box display="flex" gap={2} mb={3}>
        <TextField
          label="Start Date"
          name="start"
          type="date"
          value={filters.start}
          onChange={handleFilterChange}
        />
        <TextField
          label="End Date"
          name="end"
          type="date"
          value={filters.end}
          onChange={handleFilterChange}
        />
        <Button variant="contained" onClick={fetchCourses}>
          Apply Filters
        </Button>
      </Box>

      <Button variant="contained" startIcon={<Add />} onClick={handleOpen}>
        Add Course
      </Button>

      {/* Course Table */}
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Members</TableCell>
              <TableCell>Max Members</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Updated By</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((course) => (
              <TableRow key={course._id}>
                <TableCell>{new Date(course.start).toLocaleString()}</TableCell>
                <TableCell>
                  {course.end ? new Date(course.end).toLocaleString() : "N/A"}
                </TableCell>
                <TableCell>
                  {course.members.length > 0 ? (
                    <a
                      className="underline font-bold"
                      href={`/RegistrationsManager/${course._id}`}
                    >
                      {course.members.length}
                    </a>
                  ) : (
                    <p>{course.members.length}</p>
                  )}
                </TableCell>
                <TableCell>{course.maxMembers}</TableCell>
                <TableCell>{course.createdBy}</TableCell>
                <TableCell>{course.updatedBy}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEdit(course)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => handleDelete(course._id)}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Adding/Editing Course */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editing ? "Edit Course" : "Add Course"}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Start Date"
            name="start"
            type="datetime-local"
            focused
            value={formData.start}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label="End Date"
            focused
            name="end"
            type="datetime-local"
            value={formData.end}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Max Members"
            name="maxMembers"
            type="number"
            value={formData.maxMembers}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Coursmanager;
