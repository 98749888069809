import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { makeRequest } from "../../makeRequest";

const TrainerList = () => {
  const [trainers, setTrainers] = useState([]);

  // Fetch trainers from the API
  const fetchTrainers = async () => {
    try {
      const response = await makeRequest.get("/trainers");
      const trainers = response.data;

      // Map over trainers and fetch the signed URL for each image
      const trainersWithImages = await Promise.all(
        trainers.map(async (trainer) => {
          if (trainer.image) {
            try {
              // Fetch signed URL for the image
              const imageResponse = await makeRequest.get(
                `/images/${trainer.image}`
              );
              return { ...trainer, image: imageResponse.data.url }; // Update trainer.image with the URL
            } catch (err) {
              console.error(
                `Error fetching image for trainer ${trainer.id}:`,
                err
              );
              return trainer; // Return trainer without modifying image if fetching URL fails
            }
          }
          return trainer; // If no image, return trainer as is
        })
      );

      setTrainers(trainersWithImages);
    } catch (err) {
      console.error("Error fetching trainers:", err);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const response = await makeRequest.delete(`/trainers/${id}`);
        alert(response.data.message);
        fetchTrainers();
      } catch (error) {
        alert(error.response?.data?.error || "An error occurred");
      }
    }
  };

  useEffect(() => {
    fetchTrainers();
  }, []);

  return (
    <Box className="poppins" sx={{ p: 4 }}>
      <Button variant="contained" sx={{ my: 2 }} onClick={fetchTrainers}>
        Refresh List
      </Button>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
          gap: 2,
        }}
      >
        {trainers.map((trainer) => (
          <Card
            key={trainer._id}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <CardMedia
              component="img"
              height="200"
              image={trainer.image} // Ensure image URL is signed
              alt={trainer.firstname}
            />
            <CardContent sx={{ height: "100%" }}>
              <Typography variant="h6">
                {trainer.firstname} {trainer.lastname}
              </Typography>
              <Typography variant="body2">
                <strong>Occupations:</strong> {trainer.occupations.join(", ")}
              </Typography>
              <Typography variant="body2">
                <strong>Message:</strong> {trainer.message}
              </Typography>
              {trainer.instagram !== "" && (
                <Typography variant="body2">
                  <strong>Instagram:</strong>{" "}
                  <a
                    className="underline"
                    target="_blank"
                    href={trainer.instagram}
                  >
                    Link
                  </a>
                </Typography>
              )}
              {trainer.facebook !== "" && (
                <Typography variant="body2">
                  <strong>Facebook:</strong>{" "}
                  <a
                    className="underline"
                    target="_blank"
                    href={trainer.facebook}
                  >
                    Link
                  </a>
                </Typography>
              )}
            </CardContent>

            <Button
              color="error"
              variant="contained"
              onClick={(e) => handleDelete(trainer._id)}
            >
              Delete User
            </Button>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default TrainerList;
