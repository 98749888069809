import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeRequest } from "../makeRequest";
import Title from "../components/Title";

function CreateClient() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [registration, setRegistration] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);

  // Fetch Current User

  useEffect(() => {
    const fetchRegistration = async () => {
      try {
        const response = await makeRequest.get("/registrations/" + id);
        setRegistration(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchUser = async () => {
      try {
        const response = await makeRequest.get("/users/me");
        setUser(response.data);
        setIsAuthorized(["user", "admin"].includes(response.data.role));
        if (["user", "admin"].includes(response.data.role)) {
          fetchRegistration();
        }
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };

    fetchUser();
  }, []);

  const createClient = async () => {
    try {
      const response = await makeRequest
        .get("/registrations/create-client/" + id)
        .then(() => {
          setLoading(true);
          setTimeout(() => {
            window.close();
          }, 2000);
        });
    } catch (error) {
      console.error("Error fetching user:", error.message);
      setLoading(true);
      setTimeout(() => {
        window.close();
      }, 2000);
    }
  };

  const deleteRegistration = async () => {
    try {
      const response = await makeRequest
        .get("/registrations/delete-registration/" + id)
        .then(() => {
          setLoading(true);
          setTimeout(() => {
            window.close();
          }, 2000);
        });
    } catch (error) {
      console.error("Error fetching user:", error.message);
      setLoading(true);
      setTimeout(() => {
        window.close();
      }, 2000);
    }
  };

  return (
    <>
      {isAuthorized ? (
        <div className="w-screen h-screen poppins flex sm:flex-col  gap-8 justify-center items-center gap">
          <>
            {!loading ? (
              <div className="flex flex-col items-center gap-8">
                <div>
                  Confirm{" "}
                  <b>
                    {registration?.firstname + " " + registration?.lastname}?
                  </b>
                </div>
                <div className="flex gap-4">
                  <Button
                    onClick={createClient}
                    variant="contained"
                    color="success"
                  >
                    Confirm Client
                  </Button>
                  <Button
                    onClick={deleteRegistration}
                    variant="contained"
                    color="error"
                  >
                    Deny Registration
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center gap-8">
                <p className="font-bold ">Page will close in 2s..</p>
                <CircularProgress></CircularProgress>
              </div>
            )}
          </>
        </div>
      ) : (
        <div className="min-h-screen  fade-up flex flex-col items-center justify-center">
          <Title word={"User Access"}></Title>
          <Title word={"Only"}></Title>
        </div>
      )}
    </>
  );
}

export default CreateClient;
