import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeRequest } from "../makeRequest";
import Title from "../components/Title";

function CancelRegistration() {
  const { id } = useParams();

  const deleteRegistration = async () => {
    try {
      const response = await makeRequest
        .get("/registrations/delete-registration/" + id)
        .then(() => {
          // window.close();
        });
    } catch (error) {
      console.error("Error fetching user:", error.message);
    }
  };

  return (
    <>
      <div className="w-screen h-screen poppins flex sm:flex-col  gap-8 justify-center items-center gap">
        <Button onClick={deleteRegistration} variant="contained" color="error">
          Cancel Registration
        </Button>
      </div>
    </>
  );
}

export default CancelRegistration;
