import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { delay, motion } from "framer-motion";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Confirmation from "../components/Confirmation";
import { makeRequest } from "../makeRequest";

function Register() {
  const { id } = useParams();
  const dayjs = require("dayjs");
  const [loading, setLoading] = useState(false);
  const [birth, setBirth] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [phone, setPhone] = useState("");
  const [errorDate, setErrorDate] = useState(null);
  const [matureError, setMatureError] = useState(null);
  const [loadingForm, setLoadingForm] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [contactMethod, setContactMethod] = useState("");
  const [formData, setFormData] = useState({
    id,
    firstname: "",
    lastname: "",
    birth: "",
    email: "",
    phone: "",
  });

  const handleEmailChange = async (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);

    handleValidation();

    setTimeout(() => {
      // Regular expression for basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(inputValue)) {
        setError(true);
      } else {
        setError(false);
      }
    }, 1200); // Simulating a 500ms debounce
  };

  const handleValidation = () => {
    setLoading(true);

    // Simulate an asynchronous validation process
    setTimeout(() => {
      setLoading(false);
    }, 1500); // Simulating a 2-second validation delay
  };

  const handlePhoneNumberChange = (event) => {
    // Remove non-numeric characters from input
    const rawInput = event.target.value.replace(/\D/g, "");
    const name = event.target.name;

    // Ensure only the first 10 digits are considered
    const trimmedInput = rawInput.slice(0, 10);

    // Format phone number with spaces after the third and sixth digit
    let formattedNumber = "";
    for (let i = 0; i < trimmedInput.length; i++) {
      if (i === 3 || i === 6) {
        formattedNumber += " ";
      }
      formattedNumber += trimmedInput[i];
    }
    if (name === "phone") {
      setPhone(formattedNumber);
    }
  };

  useEffect(() => {
    if (birth) {
      setErrorDate({});
    }
  }, [birth]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (birth === null || birth === "") {
      setErrorDate({
        helperText: "Input is required",
        error: true,
      });
    } else {
      setErrorDate({});
      var startDate = new Date(birth);
      var currentDate = new Date();
      const mature =
        (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
          (currentDate.getMonth() - startDate.getMonth()) >=
        215;

      if (!mature) {
        setMatureError({
          helperText: "Must be over 18.",
          error: true,
        });
      } else {
        setLoadingButton(true);
        setMatureError({});
        formData.birth = birth;
        setLoadingForm(true);
        postRegister({ formData, contactMethod });
        setTimeout(() => {
          setLoadingForm(false);
        }, 3000);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Update the corresponding form field in the state
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  const postRegister = async (data) => {
    await makeRequest
      .post("/registrations", data)
      .then(async (response) => {
        setConfirmed(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setRegisterError(true);
      });

    setCompleted(true);
  };

  return (
    <div className="min-h-screen  sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center">
      <div
        id="torn_edge_banner"
        class="torn_container torn_left sm-shadow torn_right my-16"
      >
        <div></div>
        <div className="w-fit md:text-nowrap px-4 py-2 flex justify-center ">
          <h1 className="text-center xxs-shadow w-fit sm:text-6xl md:text-8xl title">
            Inscription
          </h1>
        </div>
      </div>
      {registerError ? (
        <div className="h-screen w-screen fixed flex flex-col gap-4 justify-center items-center">
          {" "}
          <div className="flex flex-col p-8 gap-7 justify-center items-center bg-white rounded">
            <p className="text-black font-bold">Erreur</p>
            <div className={`bouncy-transition hover:scale-110`}>
              <a
                href={`/Cours`}
                className={`bg-black relative text-white title border-4 border-black select-none cursor-pointer fade-in xs-shadow p-2 rounded-xl text-4xl z-40`}
              >
                Retour au cours
              </a>
            </div>
          </div>
        </div>
      ) : completed ? (
        <Confirmation
          confirmed={confirmed}
          email={formData.email}
        ></Confirmation>
      ) : (
        <div className="lg:w-1/2 sm:w-5/6 mx-auto sm-shadow flex justify-center ">
          <div className="bg-white poppins p-4 w-full max-w-[500px] shadow-sm rounded relative ">
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: "all" }}
              transition={{
                duration: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
                scale: {
                  delay: 0.5,
                  type: "spring",
                  damping: 5,
                  stiffness: 100,
                  restDelta: 0.001,
                },
              }}
              variants={{
                hidden: { opacity: 0, scale: 0 },
                visible: {
                  opacity: 1,
                  scale: 0.5,
                  translate: "50% -50%",
                  rotate: "12deg",
                  zIndex: 20,
                },
              }}
              className="absolute top-0  right-0 sm:w-16 lg:w-24 translate-x-1/3 -translate-y-1/3 xs-shadow"
              src={`${process.env.PUBLIC_URL}/assets/heart_eyes.png`}
            ></motion.img>
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: "all" }}
              transition={{
                duration: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
                scale: {
                  delay: 0.5,
                  type: "spring",
                  damping: 5,
                  stiffness: 100,
                  restDelta: 0.001,
                },
              }}
              variants={{
                hidden: { opacity: 0, scale: 0 },
                visible: {
                  opacity: 1,
                  scale: 0.5,
                  translate: "-50% -50%",
                  rotate: "-12deg",
                  zIndex: 20,
                },
              }}
              className="absolute top-0 left-0  sm:w-16 lg:w-24 xs-shadow"
              src={`${process.env.PUBLIC_URL}/assets/heart_eyes.png`}
            ></motion.img>
            <div>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col p-4 w-full  "
              >
                <div className="flex flex-col sm:w-full gap-4 font-Poppins">
                  <h1 className="sm:mx-1 m-3 text-lg">Infos personnelles</h1>

                  <TextField
                    id="standard-basic"
                    label="Prénom"
                    name="firstname"
                    autoComplete="given-name"
                    variant="outlined"
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    id="standard-basic"
                    label="Nom"
                    name="lastname"
                    autocomplete="family-name"
                    variant="outlined"
                    onChange={handleChange}
                    required
                  />
                  <div className="flex gap-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        onChange={(e) => {
                          // @ts-ignore
                          setBirth(e?.$d ? e.$d : null);
                        }}
                        maxDate={dayjs(Date.now())}
                        slotProps={{
                          textField: {
                            required: true,
                            label: "Date de Naissance",
                            name: "birth",
                            ...errorDate,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <FormControl>
                    <InputLabel id="email-label" required>
                      Email
                    </InputLabel>
                    <OutlinedInput
                      id="standard-basic"
                      label="Adresse Courriel"
                      type="email"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => {
                        handleEmailChange(e);
                        handleChange(e);
                      }}
                      error={Boolean(error) && email !== ""}
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          {email === "" ? (
                            <div></div>
                          ) : loading ? (
                            <CircularProgress size={20} />
                          ) : error ? (
                            <CloseIcon color="error"></CloseIcon>
                          ) : (
                            <DoneIcon></DoneIcon>
                          )}
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <TextField
                    id="standard-basic"
                    label="Numéro de telephone"
                    value={phone}
                    autocomplete="tel"
                    name="phone"
                    variant="outlined"
                    onChange={(e) => {
                      handlePhoneNumberChange(e);
                      handleChange(e);
                    }}
                    required
                  ></TextField>
                  <p className="text-center text-red-600 font-bold">
                    {errorDate?.helperText && "Birthdate is Required *"}
                    {matureError?.helperText}
                  </p>
                  <div className="p-4 bg-black rounded text-white text-sm text-center">
                    S'il sagit de votre premiere inscription, vous serez
                    contacté pour confirmer votre identité.
                  </div>
                  <FormControl>
                    <RadioGroup
                      row
                      name="contactMethod"
                      value={contactMethod}
                      onChange={(e) => setContactMethod(e.target.value)}
                      required
                    >
                      <FormControlLabel
                        value="phone"
                        control={<Radio required={true} />}
                        label="Téléphone"
                      />
                      <FormControlLabel
                        value="email"
                        control={<Radio required={true} />}
                        label="Email"
                      />
                      <FormControlLabel
                        value="txt"
                        control={<Radio required={true} />}
                        label="Texte (SMS)"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="w-full flex justify-center pt-16  overflow-hidden ">
                  <div className="w-4/5 relative register-button">
                    <img
                      className="absolute w-10 left-1/2 xxs-shadow"
                      src={`${process.env.PUBLIC_URL}/assets/smiling_imp.png`}
                    ></img>
                    <img
                      className="absolute w-10 right-1/2 xxs-shadow"
                      src={`${process.env.PUBLIC_URL}/assets/smiling_imp.png`}
                    ></img>
                    <img
                      className="absolute w-20 left-1/2 xxs-shadow"
                      src={`${process.env.PUBLIC_URL}/assets/smiling_imp.png`}
                    ></img>
                    <Button
                      sx={{
                        width: "100%",
                        border: "solid 4px black",
                        background: "white",
                        color: "black",
                        fontFamily: "Poppins",
                        "&:hover": { backgroundColor: "#e0e0e0" },
                      }}
                      type="submit"
                      variant="contained"
                      size="large"
                      className="title"
                    >
                      {loadingButton ? (
                        <CircularProgress></CircularProgress>
                      ) : (
                        "S'inscrire"
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
