import { Button } from "@mui/material";
import { makeRequest } from "../../makeRequest";

const DeleteUserButton = ({ userId }) => {
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const response = await makeRequest.delete(`/users/${userId}`);
        alert(response.data.message);
      } catch (error) {
        alert(error.response?.data?.error || "An error occurred");
      }
    }
  };

  return (
    <div className="w-full flex justify-center">
      <Button
        sx={{ maxWidth: "100px" }}
        color="error"
        variant="contained"
        onClick={handleDelete}
      >
        Delete User
      </Button>
    </div>
  );
};

export default DeleteUserButton;
