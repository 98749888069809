import { useState } from "react";
import { makeRequest } from "../../makeRequest";
import { Button, TextField } from "@mui/material";

const ChangePasswordForm = ({ userId }) => {
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await makeRequest.put(`/users/${userId}/password`, {
        password,
      });
      alert(response.data.message);
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  return (
    <form className="poppins" onSubmit={handleSubmit}>
      <TextField
        variant="standard"
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        sx={{ maxWidth: "300px" }}
      />
      <Button className="poppins" variant="contained" type="submit">
        Change Password
      </Button>
    </form>
  );
};

export default ChangePasswordForm;
