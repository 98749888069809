import React, { useEffect, useState } from "react";
import { delay, motion } from "framer-motion";
import Title from "../components/Title";
import EmojiLogo from "../components/EmojiLogo";
import Trainer from "../components/Trainer";
import { makeRequest } from "../makeRequest";

function About() {
  const [trainers, setTrainers] = useState([]);

  // Fetch trainers from the API
  const fetchTrainers = async () => {
    try {
      const response = await makeRequest.get("/trainers");
      const trainers = response.data;

      // Map over trainers and fetch the signed URL for each image
      const trainersWithImages = await Promise.all(
        trainers.map(async (trainer) => {
          if (trainer.image) {
            try {
              // Fetch signed URL for the image
              const imageResponse = await makeRequest.get(
                `/images/${trainer.image}`
              );
              return { ...trainer, image: imageResponse.data.url }; // Update trainer.image with the URL
            } catch (err) {
              console.error(
                `Error fetching image for trainer ${trainer.id}:`,
                err
              );
              return trainer; // Return trainer without modifying image if fetching URL fails
            }
          }
          return trainer; // If no image, return trainer as is
        })
      );

      setTrainers(trainersWithImages);
    } catch (err) {
      console.error("Error fetching trainers:", err);
    }
  };

  useEffect(() => {
    fetchTrainers();
  }, []);

  return (
    <div className="min-h-screen sm:mt-8 sm:mb-36 md:my-36 fade-up flex flex-col items-center overflow-hidden">
      <div className="my-16">
        <Title word="Mission" />
      </div>
      <div className="lg:w-1/2 h-fit flex flex-col bg-white border-[16px] border-black rounded sm:w-full  mx-auto sm-shadow">
        <div className="w-full h-[400px] flex justify-center items-center p-8 bg--white relative">
          {" "}
          <motion.div
            className={`sm:w-5/6 sm:min-w-[400px] md:w-1/2 lg:w-1/2 h-full  flex justify-center  relative `}
          >
            <div className="w-full flex justify-center items-center -rotate-[20deg]">
              <EmojiLogo></EmojiLogo>
              <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 480"
                className="logoAnimation2 absolute flex justify-center items-center xs-shadow "
              >
                {" "}
                <motion.path
                  d="M322,32.5c110.18,0,199.5,89.32,199.5,199.5S432.18,431.5,322,431.5S122.5,342.18,122.5,232
			S211.82,32.5,322,32.5 M322,5.5c-30.57,0-60.23,5.99-88.17,17.81c-26.97,11.41-51.19,27.74-71.99,48.53
			c-20.79,20.79-37.12,45.01-48.53,71.99C101.49,171.77,95.5,201.43,95.5,232s5.99,60.23,17.81,88.17
			c11.41,26.97,27.74,51.19,48.53,71.99c20.79,20.79,45.01,37.12,71.99,48.53c27.94,11.82,57.61,17.81,88.17,17.81
			s60.23-5.99,88.17-17.81c26.97-11.41,51.19-27.74,71.99-48.53c20.79-20.79,37.12-45.01,48.53-71.99
			c11.82-27.94,17.81-57.61,17.81-88.17s-5.99-60.23-17.81-88.17c-11.41-26.97-27.74-51.19-48.53-71.99
			c-20.79-20.79-45.01-37.12-71.99-48.53C382.23,11.49,352.57,5.5,322,5.5L322,5.5z"
                />
                <motion.path
                  d="M163.5,142.5c-1.61,5.82-3,112-3,112c0.61,2.64,18.75,8.33,18,4l2-44c16.76,23.6,30.15,39.83,38,44
		c1.08,1.08,10.08-7.92,9-9l-30-47l32-55c1.4-1.87-10.6-10.87-12-9c-11.85,13.3-24.31,32.49-37,54v-46
		C181.32,143.03,164.32,139.03,163.5,142.5z"
                />
                <motion.path d="M238.5,144.5l-1,109c0,5,19,10,19,5v-112C256.5,142.5,238.5,138.5,238.5,144.5z" />
                <motion.path
                  d="M309.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C293.23,178.91,295.5,165.5,309.5,160.5z
		"
                />
                <motion.path
                  d="M309.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
      c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
      c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C293.23,178.91,295.5,165.5,309.5,160.5z
      "
                />
                <motion.path
                  d="M382.5,161.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C366.23,179.91,368.5,166.5,382.5,161.5z
		"
                />
                <motion.path
                  d="M456.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C440.23,178.91,442.5,165.5,456.5,160.5z
		"
                />
              </motion.svg>
            </div>
          </motion.div>
        </div>
        <div className="w-full h-fit flex flex-col items-center">
          <div className="w-5/6 border-y-2 border-black  flex items-center flex-col ">
            {" "}
            <p className="title text-7xl text-center text-balance leading-[4rem] xs-shadow ">
              KEEP IT SUPER SIMPLE & STUPID
            </p>
          </div>
          <div className="w-5/6 p-8 text-pretty text-center">Notre Mission</div>
        </div>
      </div>
      <div className="my-16">
        <Title word="Notre équipe" />
      </div>
      <div className="xl:w-2/3 lg:w-4/5 sm:gap-8 lg:gap-0  py-24 flex flex-col h-fit sm:w-full  ">
        {trainers.map((trainer, index) => (
          <Trainer trainer={trainer} odd={index % 2}></Trainer>
        ))}
      </div>
    </div>
  );
}

export default About;

{
  /* <motion.div
            style={{
              transition: "transform 0.1s ease-out",
              transformOrigin: "0% 0%",
              position: "fixed", // To keep it fixed as the page scrolls
              top: 0,
              left: 0,
            }}
            className={`sm:w-full h-full  flex justify-center relative `}
          >
            <div className="w-full flex justify-center items-center -rotate-[20deg]">
              <EmojiLogo></EmojiLogo>
              <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 480"
                className="logoAnimation2 absolute flex justify-center items-center sm-shadow "
              >
                {" "}
                <motion.path
                  d="M322,32.5c110.18,0,199.5,89.32,199.5,199.5S432.18,431.5,322,431.5S122.5,342.18,122.5,232
			S211.82,32.5,322,32.5 M322,5.5c-30.57,0-60.23,5.99-88.17,17.81c-26.97,11.41-51.19,27.74-71.99,48.53
			c-20.79,20.79-37.12,45.01-48.53,71.99C101.49,171.77,95.5,201.43,95.5,232s5.99,60.23,17.81,88.17
			c11.41,26.97,27.74,51.19,48.53,71.99c20.79,20.79,45.01,37.12,71.99,48.53c27.94,11.82,57.61,17.81,88.17,17.81
			s60.23-5.99,88.17-17.81c26.97-11.41,51.19-27.74,71.99-48.53c20.79-20.79,37.12-45.01,48.53-71.99
			c11.82-27.94,17.81-57.61,17.81-88.17s-5.99-60.23-17.81-88.17c-11.41-26.97-27.74-51.19-48.53-71.99
			c-20.79-20.79-45.01-37.12-71.99-48.53C382.23,11.49,352.57,5.5,322,5.5L322,5.5z"
                />
                <motion.path
                  d="M163.5,142.5c-1.61,5.82-3,112-3,112c0.61,2.64,18.75,8.33,18,4l2-44c16.76,23.6,30.15,39.83,38,44
		c1.08,1.08,10.08-7.92,9-9l-30-47l32-55c1.4-1.87-10.6-10.87-12-9c-11.85,13.3-24.31,32.49-37,54v-46
		C181.32,143.03,164.32,139.03,163.5,142.5z"
                />
                <motion.path d="M238.5,144.5l-1,109c0,5,19,10,19,5v-112C256.5,142.5,238.5,138.5,238.5,144.5z" />
                <motion.path
                  d="M309.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C293.23,178.91,295.5,165.5,309.5,160.5z
		"
                />
                <motion.path
                  d="M309.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
      c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
      c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C293.23,178.91,295.5,165.5,309.5,160.5z
      "
                />
                <motion.path
                  d="M382.5,161.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C366.23,179.91,368.5,166.5,382.5,161.5z
		"
                />
                <motion.path
                  d="M456.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C440.23,178.91,442.5,165.5,456.5,160.5z
		"
                />
              </motion.svg>
            </div>
          </motion.div> */
}
